<template>
  <div class='admin'>
    <div class="admin-left box-shadow">
      <div class="item" :class="item.active ? 'active' : ''" v-for="(item, index) in list" :key="index"
        @click="goRouterPage(item)">
        <span class="icon" :class="item.class"></span>
        <span class="name">{{ item.name }}</span>
      </div>
    </div>
    <div class="admin-right box-shadow">
      <div class="right-top">
        <span class="fa fa-navicon"></span>
        <span @click="goPage" class="hove">首页</span>
        <span v-show="activeItem.name !== '首页'">/</span>
        <span v-show="activeItem.name !== '首页'">{{ activeItem.name }}</span>
      </div>
      <div class="router">
        <div class="item" :class="item.active ? 'active' : ''" v-for="(item, index) in routerList" :key="index"
          @click="routerGo(item)">
          <div class="icon" v-show="item.active"></div>
          <div class="name">{{ item.name }}</div>
          <img class="close" @click.stop="closeRouter(index)" v-show="item.name !== '首页'" src="@/assets/img/close.png"
            alt="">
        </div>
      </div>
      <div class="page-body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      list: [
        { class: 'fa fa-home', name: '首页', active: true, router: '/admin' },
        { class: 'fa fa-user', name: '用户列表', active: false, router: '/admin/userAdmin' },
        { class: 'fa fa-book', name: '文章列表', active: false, router: '/admin/articleAdmin' },
        { class: 'fa fa-book', name: '卡密管理', active: false, router: '/admin/code' },
      ],
      routerList: [
        { name: '首页', router: '/admin', active: true }
      ],
      activeItem: { name: '首页', router: '/admin', active: true }
    };
  },
  created() {
    let item = localStorage.getItem('activeItem')
    if (item) {
      item = JSON.parse(item)
      this.activeItem = item
      if (item.name == '首页') return
      this.list.forEach(i => {
        i.active = false
        if (i.router == item.router) i.active = true
      })
      this.routerList.push(item)
      this.routerList.forEach(i => {
        i.active = false
        if (i.router == item.router) i.active = true
      })

    }
  },
  methods: {
    closeRouter(index) {
      if (this.routerList[index].active) {
        this.routerList.splice(index, 1)
        this.activeItem = this.routerList[this.routerList.length - 1]
        this.activeItem.active = true
        this.list.forEach(ite => {
          ite.active = false
          if (ite.router == this.activeItem.router) ite.active = true
        })
        this.$router.push(this.routerList[this.routerList.length - 1].router)
      } else {
        this.routerList.splice(index, 1)
      }
    },
    routerGo(item) {
      if (this.activeItem.router == item.router) return
      localStorage.setItem('activeItem', JSON.stringify(item))
      this.activeItem = item
      this.routerList.forEach(item => {
        item.active = false
      })
      item.active = true
      this.list.forEach(ite => {
        ite.active = false
        if (ite.router == item.router) ite.active = true
      })
      this.$router.push(item.router)
    },
    goRouterPage(item) {
      if (this.activeItem.router == item.router) return
      localStorage.setItem('activeItem', JSON.stringify(item))
      this.activeItem = item
      this.list.forEach(item => {
        item.active = false
      })
      item.active = true
      let contain = false
      for (let i = 0; i < this.routerList.length; i++) {
        let ite = this.routerList[i]
        ite.active = false
        if (ite.router == item.router) {
          contain = true
          ite.active = true
        }
      }
      if (!contain) {
        this.routerList.forEach(i => {
          i.active = false
        })
        this.routerList.push(item)
      }
      this.$router.push(item.router)
    },
    goPage() {
      if (this.activeItem.name == '首页') return
      this.routerList.forEach(i => {
        i.active = false
      })
      this.list.forEach(item => {
        item.active = false
      })
      this.routerList[0].active = true
      this.list[0].active = true
      this.activeItem = { name: '首页', router: '/admin', active: true }
      this.$router.push('/admin')
    }
  },
}
</script>

<style lang='scss' scoped>
.page-body {
  padding: 10px;
}

.router {
  display: flex;
  padding: 6px 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);

  .item {
    padding: 3px 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #d8dce5;
    cursor: pointer;

    .icon {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #fff;
    }

    .name {
      margin: 0 6px;
      font-size: 14px;
    }

    .close {
      width: 10px;
      height: 10px;
      border-radius: 50%;

      &:hover {
        background: #b8c2e5;
      }
    }
  }

  .active {
    color: #fff;
    background: #4ebe8c;
  }
}

* {
  user-select: none;
}

.admin {
  width: 100%;
  display: flex;

  .admin-left {
    width: 200px;

    .active {
      background: #eeeeee;
    }

    .item {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #eeeeee;
      }

      .icon {
        width: 30px;
      }
    }
  }

  .admin-right {
    margin-left: 10px;
    width: calc(100% - 30px);

    .right-top {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      border-bottom: 1px solid #eeeeee;


      :nth-child(2) {
        margin: 0 6px;
      }

      :nth-child(3) {
        color: #97a8be;
        margin-right: 6px;
      }

      :nth-child(4) {
        font-size: 12px;
        color: #97a8be;
      }
    }
  }
}
</style>