<template>
  <div>
    <div class="userItem" v-for="(item, index) in userList" :key="index">
      <div class="avatar">
        <img :class="item.socketId ? '' : 'Offline-img'" :src="item.avatarUrl" alt="">
        <div class="online" :class="item.socketId ? '' : 'Offline'"></div>
      </div>
      <div class="userName" :class="item.socketId ? '' : 'Offline-name'">{{ item.nickname || item.userName }}</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    userList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>
.userItem {
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;

  .userName {
    margin-left: 10px;
    color: #fff;
  }

  .avatar {
    width: 34px;
    height: 34px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .online {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: #16c60c;
    }
  }

  .Offline-img {
    filter: grayscale(100%);
  }

  .Offline-name {
    color: #9b9d99 !important;
  }

  .Offline {
    background: #9b9d99 !important;
  }
}
</style>