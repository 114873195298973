<template>
  <div class="tank" ref="tankPage">
    <!-- <div class="enemyKills">已击杀：{{ enemyKills }}</div> -->
    <canvas ref="gameCanvas"></canvas>
  </div>
</template>

<script>
export default {
  data() {
    return {
      canvas: null,
      ctx: null,
      enemyKills: 0, // 记录击败敌人的数量
      player: {
        x: 0,
        y: 0,
        radius: 20,
        speed: 5,
        gunLength: 30,
        gunWidth: 10,
        angle: 0,
        health: 100 // 玩家初始血量
      },
      bullets: [],
      enemies: [],
      explosions: [],
      keys: {},
      mouse: { x: 0, y: 0 },
      isScreenShaking: false,
      shakeIntensity: 5,
      shakeDuration: 300,
      maxExplosionRadius: 50,
      explosionSpeed: 3,
      Interval: null,
      shakeInterval: null,
      animationList: []
    };
  },
  mounted() {
    // console.log(this.$refs.tankPage.offsetHeight)
    this.canvas = this.$refs.gameCanvas;
    this.ctx = this.canvas.getContext("2d");
    this.canvas.width = this.$refs.tankPage.offsetWidth;
    this.canvas.height = this.$refs.tankPage.offsetHeight

    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("keyup", this.handleKeyup);
    window.addEventListener("mousemove", this.handleMousemove);
    window.addEventListener("mousedown", this.spawnBullet);

    this.player.x = this.canvas.width / 2;
    this.player.y = this.canvas.height / 2;

    this.Interval = setInterval(this.spawnEnemy, 2000);
    this.gameLoop()
  },
  methods: {
    handleKeydown(e) {
      this.keys[e.key] = true;
    },
    handleKeyup(e) {
      this.keys[e.key] = false;
    },
    handleMousemove(e) { // 鼠标位置
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
    },
    drawPlayer() { // 绘制玩家
      const { x, y, radius, gunLength, gunWidth, angle, health } = this.player;
      this.ctx.beginPath();
      this.ctx.arc(x, y, radius, 0, Math.PI * 2);
      this.ctx.fillStyle = "blue";
      this.ctx.fill();
      this.ctx.closePath();

      // 显示血量
      this.ctx.fillStyle = "red";
      this.ctx.font = "16px Arial";
      this.ctx.fillText(`HP: ${health}`, x - 20, y - 30);
      const dx = this.mouse.x - x;
      const dy = this.mouse.y - y - 59;
      this.player.angle = Math.atan2(dy, dx);

      this.ctx.save();
      this.ctx.translate(x, y);
      this.ctx.rotate(this.player.angle);
      this.ctx.fillStyle = "blue";
      this.ctx.fillRect(0, -gunWidth / 2, gunLength, gunWidth);
      this.ctx.restore();
    },
    updatePlayerPosition() { // 控制玩家方向
      if (this.keys["ArrowUp"] || this.keys["w"]) this.player.y -= this.player.speed;
      if (this.keys["ArrowDown"] || this.keys["s"]) this.player.y += this.player.speed;
      if (this.keys["ArrowLeft"] || this.keys["a"]) this.player.x -= this.player.speed;
      if (this.keys["ArrowRight"] || this.keys["d"]) this.player.x += this.player.speed;
    },
    drawBullets() { // 绘制子弹
      this.bullets.forEach(bullet => {
        this.ctx.beginPath();
        this.ctx.arc(bullet.x, bullet.y, 5, 0, Math.PI * 2);
        this.ctx.fillStyle = "red";
        this.ctx.fill();
        this.ctx.closePath();
      });
    },
    updateBullets() { // 更新子弹位置
      this.bullets.forEach(bullet => {
        bullet.x += Math.cos(bullet.angle) * bullet.speed;
        bullet.y += Math.sin(bullet.angle) * bullet.speed;
      });
      this.bullets = this.bullets.filter(
        bullet =>
          bullet.x >= 0 &&
          bullet.x <= this.canvas.width &&
          bullet.y >= 0 &&
          bullet.y <= this.canvas.height
      );
    },
    spawnBullet() { // 添加子弹
      this.bullets.push({
        x: this.player.x,
        y: this.player.y,
        speed: 8,
        angle: this.player.angle
      });
    },
    spawnEnemy() { // 添加豆豆
      const spawnSide = Math.floor(Math.random() * 4);
      let spawnX, spawnY;
      switch (spawnSide) {
        case 0: // Top
          spawnX = Math.random() * this.canvas.width;
          spawnY = 0;
          break;
        case 1: // Right
          spawnX = this.canvas.width;
          spawnY = Math.random() * this.canvas.height;
          break;
        case 2: // Bottom
          spawnX = Math.random() * this.canvas.width;
          spawnY = this.canvas.height;
          break;
        case 3: // Left
          spawnX = 0;
          spawnY = Math.random() * this.canvas.height;
          break;
      }
      this.enemies.push(new Enemy(spawnX, spawnY));
    },
    drawEnemies() { // 绘制豆豆
      this.enemies.forEach(enemy => enemy.draw(this.ctx));
    },
    updateEnemies() { // 更新豆豆位置
      this.enemies.forEach(enemy => enemy.update(this.player));
    },
    checkCollision() { // 判断是否击败敌人
      this.bullets.forEach((bullet, bulletIndex) => {
        this.enemies.forEach((enemy, enemyIndex) => {
          const dx = bullet.x - enemy.x;
          const dy = bullet.y - enemy.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < enemy.radius) {
            this.explosions.push({
              x: enemy.x,
              y: enemy.y,
              radius: 1,
              speed: this.explosionSpeed
            });
            this.enemyKills++; // 每击败一个敌人，计数增加
            this.bullets.splice(bulletIndex, 1);
            this.enemies.splice(enemyIndex, 1);
          }
        });
      });
    },
    checkPlayerCollision() { // 豆豆是否撞击玩家
      this.enemies.forEach((enemy, enemyIndex) => {
        const dx = this.player.x - enemy.x;
        const dy = this.player.y - enemy.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < this.player.radius + enemy.radius) {
          this.shakeScreen();
          this.player.health -= 10; // 每次碰撞减少10点血量
          if (this.player.health <= 0) {
            this.$message.waring('Game Over!')
            this.removeEvent()
            clearInterval(this.Interval)
            this.Interval = null
            clearInterval(this.shakeInterval);
            this.shakeInterval = null
            // 可以在这里加入游戏结束逻辑
          }
          this.enemies.splice(enemyIndex, 1);
        }
      });
    },
    shakeScreen() { // 撞击动画
      if (!this.isScreenShaking) {
        this.isScreenShaking = true;
        const startTime = Date.now();
        this.shakeInterval = setInterval(() => {
          const currentTime = Date.now();
          const elapsedTime = currentTime - startTime;
          if (elapsedTime < this.shakeDuration) {
            const offsetX = (Math.random() - 0.5) * this.shakeIntensity;
            const offsetY = (Math.random() - 0.5) * this.shakeIntensity;
            this.ctx.translate(offsetX, offsetY);
          } else {
            this.isScreenShaking = false;
            clearInterval(this.shakeInterval);
            this.shakeInterval = null
            this.ctx.setTransform(1, 0, 0, 1, 0, 0);
          }
        }, 20);
      }
    },
    drawExplosion(x, y, radius, alpha) {
      this.ctx.beginPath();
      this.ctx.arc(x, y, radius, 0, Math.PI * 2);
      this.ctx.strokeStyle = `rgba(255, 165, 0, ${alpha})`;
      this.ctx.lineWidth = 2;
      this.ctx.stroke();
      this.ctx.closePath();
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    gameLoop() {
      this.clearCanvas();
      this.updatePlayerPosition();
      this.drawPlayer();
      this.updateBullets();
      this.drawBullets();
      this.updateEnemies();
      this.drawEnemies();
      this.checkCollision();
      this.checkPlayerCollision();

      this.explosions.forEach((explosion, index) => {
        const alpha = 1 - explosion.radius / this.maxExplosionRadius;
        this.drawExplosion(explosion.x, explosion.y, explosion.radius, alpha);
        explosion.radius += explosion.speed;
        if (explosion.radius >= this.maxExplosionRadius) {
          this.explosions.splice(index, 1);
        }
      });
      requestAnimationFrame(this.gameLoop)
    },
    removeEvent() {
      window.removeEventListener("keydown", this.handleKeydown);
      window.removeEventListener("keyup", this.handleKeyup);
      window.removeEventListener("mousemove", this.handleMousemove);
      window.removeEventListener("mousedown", this.spawnBullet);
    }
  },
  beforeDestroy() {
    this.removeEvent()
    clearInterval(this.Interval)
    this.Interval = null
    clearInterval(this.shakeInterval);
    this.shakeInterval = null
    this.removeEvent()
  }
};

class Enemy {
  constructor(x, y) {
    this.x = x;
    this.y = y;
    this.radius = 15;
    this.speed = 2;
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
    ctx.fillStyle = "orange";
    ctx.fill();
    ctx.closePath();
  }

  update(player) {
    const dx = player.x - this.x;
    const dy = player.y - this.y;
    const angleToPlayer = Math.atan2(dy, dx);
    this.x += Math.cos(angleToPlayer) * this.speed;
    this.y += Math.sin(angleToPlayer) * this.speed;
  }
}
</script>

<style scoped>
.enemyKills {
  height: 60px;
  transform: translateX(-50%);
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.tank {
  position: relative;
  width: 100vw;
  height: 100%;
  user-select: none;
  /* overflow: hidden; */
  font-family: system-ui;
  cursor: crosshair;
}

canvas {
  cursor: crosshair;
  inset: 0;
  display: block;
}
</style>
