<template>
  <div class="middle">
    <div class="middle-con">
      <div class="con" ref="chatContainer">
        <msgBox v-for="(item, index) in msgList" :key="index" @msgChange="msgChange"
          :typewriter="index == msgList.length - 1" :item="item">
        </msgBox>
      </div>
    </div>
    <messageInput @sendMsg="sendMsg"></messageInput>
  </div>
</template>

<script>
import messageInput from '@/components/messageInput.vue'
import msgBox from '@/components/msgBox.vue'
export default {
  components: {
    messageInput,
    msgBox
  },
  data() {
    return {
      user: '',
      msgList: []
    };
  },
  created() {
    this.user = this.$tool.getStorage('user')
  },
  methods: {
    sendMsg(message) {
      if (!message) return
      let item = JSON.parse(JSON.stringify(this.user))
      item.type = 0
      item.msg = message
      this.msgList.push(item)
      this.gpt(item)
      this.scrollToBottom()
    },
    msgChange(index, item) {
      this.scrollToBottom()
    },
    gpt(item) {
      let param = {
        user_input: item.msg,
        system: '你是个乐于助人的程序员',
        model_name: "gpt-3.5-turbo" // gpt-3.5-turbo/gpt-4/gpt-4-turbo
      }
      this.$http.post('/ai', param).then(res => {
        if (res.status === 1) {
          let obj = { type: 1, msg: '', userName: 'GPT' }
          if (res.result == -1) {
            obj.typewriter = "请求失败，请重试"
          } else {
            obj.typewriter = res.result.response
          }
          obj.avatarUrl = require('@/assets/logo.png')
          this.msgList.push(obj)
          this.scrollToBottom()
        }
      })
    },
    scrollToBottom() {
      // 使用$nextTick确保DOM更新后再滚动到底部
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        if (chatContainer) chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.middle {
  width: calc(100% - 300px);
  max-width: 800px;
  height: 600px;
  margin: 10px;
  background: #3b3b3b9c;
  border-radius: 10px;

  .middle-con {
    padding: 10px;
    height: calc(100% - 50px);
  }

  .con {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
