<template>
  <transition name="message-fade" @after-leave="handleAfterLeave">
    <div class='msg-box' v-show="isShowMyMessage" :class="iconType" :style="controlTop" @mouseenter="clearTimerFn"
      @mouseleave="startTimerFn">
      <img v-if="iconType == 'success'" src="@/assets/component/message/success.png" alt="">
      <img v-if="iconType == 'error'" src="@/assets/component/message/error.png" alt="">
      <img v-if="iconType == 'info'" src="@/assets/component/message/message.png" alt="">
      <img v-if="iconType == 'waring'" src="@/assets/component/message/waring.png" alt="">
      <span>{{ msg }}</span>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'message',
  props: {
    msg: {
      type: String,
      default: '成功'
    },
    iconType: {
      type: String,
      default: 'success'
    },
    cutCount: {
      type: Function
    },
    count: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      timer: null,
      isShowMyMessage: false,
      duration: 3000
    };
  },
  computed: {
    controlTop() {
      return {
        top: `${10 + (this.count - 1) * 60}px`,
      };
    },
  },
  mounted() {
    this.duration = ((this.count - 1) * 1000) + this.duration
    this.startTimerFn();
  },
  methods: {
    startTimerFn() {
      this.timer = setTimeout(() => {
        this.isShowMyMessage = false
      }, this.duration);
    },
    clearTimerFn() {
      clearTimeout(this.timer);
    },
    handleAfterLeave() {
      // 在移除一个dom之前，要先通知外界的计数count减去一个，并让余下的所有dom都往上移动，即更改位置
      this.cutCount();
      // 然后移除dom
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    }
  },
}
</script>
<style lang='scss' scoped>
* {
  box-sizing: border-box;
}

.msg-box {
  position: fixed;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  padding: 10px 15px;
  min-width: 200px;
  display: flex;
  align-items: center;
  z-index: 99999;
  border-radius: 4px;
  transition: opacity 0.3s, transform 0.4s, top 0.4s;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.success {
  color: #67c23a;
  background: #f0f9eb;
  border: 1px solid #e1f3d8;
}

.waring {
  color: #f29d50;
  background: #fff4ea;
  border: 1px solid #fdead8;
}

.info {
  color: #84888e;
  background: #e9f0fb;
  border: 1px solid #e7edf4;
}

.error {
  color: #f56c6c;
  background: #fef0f0;
  border: 1px solid #fde2e2;
}

// 过渡效果样式
.message-fade-enter,
.message-fade-leave-active {
  opacity: 0;
  transform: translate(-50%, -100%);
}
</style>