<template>
  <div class="editor">
    <div class="title">
      <input type="text" v-model="title" placeholder="请输入文章标题...">
      <div class="title-btn">发布</div>
    </div>
    <div class="desc">
      <input type="text" v-model="desc" placeholder="用一句话来描述这篇文章">
    </div>
    <editor-vue :content="content" :readOnlys="readOnlys" @changeData="hChangeData" @uploadImg="hUploadImg" />
    <div class="option">
      <div class="option-box">
        <div class="cover flex">
          <span>文章封面 : </span>
          <div class="cover-img">
            <img v-if="imgSrc" :src="imgSrc">
            <div v-else>+</div>
          </div>
        </div>
        <div class="tag flex center">
          <span>文章标签 : </span>
        </div>
        <div class="classify flex center">
          <span>文章分类 : </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import editorVue from '@/components/WangEditor/index.vue'
export default {
  components: {
    editorVue,
  },
  data() {
    return {
      readOnlys: false,
      content: ``,
      title: '',
      desc: '',
      imgSrc: ''
    };
  },
  created() {
    this.content = localStorage.getItem('html')
  },
  methods: {
    hChangeData(editDataHtml) {
      // 获取最新的html数据
      this.content = editDataHtml
      localStorage.setItem('html', editDataHtml)
      // console.log(editDataHtml)
    },
    hUploadImg(file, insertFn) {
      let formData = new FormData()
      formData.append('file', file)
      // 插入图片，调接口返回图片url,通过插入conteng
      this.$http.post('/uploadfile', formData).then(res => {
        if (res.status === 1) {
          insertFn(res.url);
        }
      })
      // 设置只读
      // this.readOnlys = true;
    },
  },
};
</script>

<style scoped lang="scss">
.cover {
  .cover-img {
    width: 160px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #cfcfcf;
    border-radius: 4px;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.flex {
  display: flex;
  margin-top: 20px;

  span {
    margin: 0 20px;
    position: relative;

    &::after {
      content: '*';
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-200%);
      color: #eb6315;
    }
  }
}

.center {
  align-items: center;
}

.option {
  max-width: 1360px;
  margin: 20px auto;
  padding: 0 10px;

  .option-box {
    padding: 1px 20px 20px;
    background: #fff;
    border-radius: 5px;
  }
}

.editor {
  height: 100vh;
  overflow-y: auto;
  background: #f5f6f7;
}

.desc {
  padding: 0 20px;
  border-top: 1px solid #ccc;
  background: #fff;

  input {
    border: none;
    height: 40px;
    font-size: 24px;

    &:focus {
      outline: none !important;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;
  background: #fff;

  input {
    border: none;
    height: 40px;
    font-size: 30px;

    &:focus {
      outline: none !important;
    }
  }

  .title-btn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px 8px;
    color: #fff;
    background: #1d7dfa;
    border-radius: 3px;
    cursor: pointer;
  }
}
</style>