<template>
  <div class=''>
    <div class="item" v-if="item.type == 1">
      <img :src="item.avatarUrl" alt="">
      <div class="msg-box">
        <div class="name">{{ item.nickname || item.userName }}</div>
        <div class="msg-txt">
          {{ item.msg }}
          <span class="cursor" v-if="typewriter"></span>
        </div>
      </div>
    </div>
    <div class="item my" v-if="item.type == 0">
      <img :src="item.avatarUrl" alt="">
      <div class="msg-txt">
        {{ item.msg }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    item: {
      type: Object,
      default: {}
    },
    typewriter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      index: 0
    };
  },
  mounted() {
    if (this.typewriter && this.item.type == 1) {
      this.index = 0
      this.type()
    }
  },
  methods: {
    type() {
      if (this.index < this.item.typewriter.length) {
        this.item.msg += this.item.typewriter[this.index++];
        this.$emit('msgChange', this.index, this.item)
        setTimeout(this.type, 100); // 调整100ms以改变打字速度
      }
    }
  },
}
</script>
<style lang='scss' scoped>
.cursor {
  height: 14px;
  display: inline-block;
  border-right: 1px solid;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: white;
  }
}

.my {
  flex-direction: row-reverse;

  .msg-txt {
    margin-left: 0 !important;
    margin-right: 10px;
    text-align: left;
    color: #fff !important;
    background: #404040 !important;

    &::after {
      left: 100% !important;
      transform: translateX(-1px) !important;
      border-left-color: #404040 !important;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
    }
  }
}

.name {
  margin-left: 10px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #fff;
}

.item {
  position: relative;
  margin-bottom: 20px;
  display: flex;

  img {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .msg-box {
    max-width: calc(100% - 50px);
  }

  .msg-txt {
    margin-left: 10px;
    vertical-align: top;
    display: inline-block;
    word-break: break-all;
    white-space: pre-wrap;
    padding: 10px;
    color: #AAAAAA;
    border-radius: 10px;
    background: #171717;
    position: relative;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);

    &::after {
      content: '';
      position: absolute;
      left: 1px;
      top: 5px;
      transform: translateX(-99%);
      width: 0;
      height: 0;
      border: 6px solid #171717;
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }
}
</style>