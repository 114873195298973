<template>
  <div class='data-page'>
    dataPage
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped></style>