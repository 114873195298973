<template>
  <div class='room'>
    <div class="left">
      <div class="out-btn" @click="outRoom">退出房间</div>
    </div>
    <div class="middle">
      <div class="title" v-if="!isWatch">
        当前状态：{{ gameStatus ? victoryUser == playerType ? '你赢了' : '你输了' : userLength == 2 ? status == '' && playerType ==
          'black' ? '该你了' : status && status
            !== playerType ? '该你了' : '等对手' : '等待玩家加入' }}
      </div>
      <div class="title" v-if="isWatch">
        当前状态：{{ gameStatus ? userType[victoryUser] + '获胜' : '轮到' + userType[moveUser] }}
      </div>
      <div class="board">
        <div class="mask"
          v-if="userLength !== 2 || (status == '' && playerType !== 'black') || (status && status == playerType) || isWatch || gameStatus">
        </div>
        <img class="gomokuBg" src="@/assets//img/gomokuBg.png" alt="">
        <div v-for="(row, rowIndex) in board" :key="rowIndex" class="row">
          <span class="heng"></span>
          <div v-for="(cell, colIndex) in row" :key="colIndex" class="cell" @click="makeMove(rowIndex, colIndex)">
            <span class="shu"></span>
            <span class="qi" :style="{ '--color': playerType }" :class="cell.class"></span>
            <span v-if="cell.circle" class="before"></span>
            <span v-if="cell.circle" class="after"></span>
          </div>
        </div>
      </div>
      <div class="message">
        <div class="message-box" ref="chatContainer">
          <div class="msg" v-for="(item, index) in msgList" :key="index">
            <div class="name">{{ item.nickName || item.userName }}：</div>
            <div class="msg-text">{{ item.msg }}</div>
          </div>
        </div>
        <messageInput @sendMsg="sendMsg"></messageInput>
      </div>
    </div>
    <div class="right">
    </div>
  </div>
</template>
<script>
import messageInput from '@/components/messageInput.vue'
export default {
  components: {
    messageInput
  },
  props: {

  },
  data() {
    return {
      board: Array(15).fill().map(() => Array(15).fill().map(() => ({ class: '', circle: '' }))),
      playerType: null,
      gameStatus: false,
      roomId: '',
      user: [],
      status: '',
      userLength: 0,
      isWatch: false,
      userType: {
        'black': '黑方',
        'white': '白方'
      },
      moveUser: 'black',
      victoryUser: '',
      msgList: [],
      user: ''
    };
  },
  created() {
    this.user = this.$tool.getStorage('user')
    this.roomId = this.$route.query.roomId
  },
  mounted() {
    this.$socket.emit('joinRoom', this.roomId)
    this.$socket.on('moveUser', moveUser => {
      this.moveUser = moveUser
    })
    this.$socket.on('playerType', playerType => {
      this.playerType = playerType
    })
    this.$socket.on('userLength', userLength => {
      this.userLength = userLength
    })
    this.$socket.on('gameInfo', data => {
      this.isWatch = true
      data.forEach((item, x) => {
        item.forEach((ite, y) => {
          this.board[x][y].class = ite
        })
      })
    })
    this.$socket.on('move', ({ x, y, color, roomId }) => {
      this.moveUser = color == 'black' ? 'white' : 'black'
      this.board[x][y].class = color;
      this.board.forEach(i => {
        i.forEach(v => {
          v.circle = ''
        })
      })
      this.board[x][y].circle = color;
      this.status = color
    });
    this.$socket.on('gameOver', type => {
      this.gameStatus = true
      this.victoryUser = type
    })
    this.$socket.on('roomMessage', item => {
      item.type = 1
      this.msgList.push(item)
      this.scrollToBottom()
    })
  },
  destroyed() {
    this.$socket.emit('outRoom', this.roomId)
  },
  methods: {
    sendMsg(message) {
      if (!message) return
      let item = JSON.parse(JSON.stringify(this.user))
      item.type = 0
      item.msg = message
      this.msgList.push(item)
      this.$socket.emit('roomMessage', item, this.roomId)
      this.scrollToBottom()
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        if (chatContainer) chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
    outRoom() {
      this.$router.go(-1)
    },
    makeMove(x, y) {
      let status = false
      if (!this.board[x][y].class) {
        if (!this.status && this.playerType == 'black') {
          status = true
        } else if (this.status && this.status != this.playerType) {
          status = true
        }
      }
      if (status) {
        this.board.forEach(i => {
          i.forEach(v => {
            v.circle = ''
          })
        })
        this.board[x][y].class = this.playerType
        this.board[x][y].circle = this.playerType;
        this.$socket.emit('move', this.roomId, x, y);
      }
    }
  },
}
</script>
<style lang='scss' scoped>
.message {
  margin: 10px 20px 0;
  height: 150px;
  overflow-y: auto;
  background: #1e1e1e;
  border-radius: 5px;

  .message-box {
    padding: 10px;
    height: calc(100% - 50px);
    color: #fff;
    overflow-y: auto;

    .msg-text {
      margin-top: 2px;
      font-size: 14px;
      color: #dbdbdb;
      word-break: break-all;
    }

    .msg {
      display: flex;
      margin-bottom: 5px;

      .name {
        padding-bottom: 5px;
      }
    }
  }
}

.out-btn {
  color: #fff;
  cursor: pointer;
}

.cell .before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 26px;
  height: 26px;
  border: 1px solid #fff;
  opacity: 0;
  border-radius: 50%;
  animation: ripple 1s linear infinite;
  animation-play-state: running;
  z-index: 1;
}

.cell .after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  border: 2px solid #fff;
  opacity: 0;
  border-radius: 50%;
  animation: ripple-effect 2s linear infinite;
  animation-delay: 1s;
  animation-play-state: running;
  z-index: 1;
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@keyframes ripple-effect {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}

.title {
  margin: 10px 0;
  text-align: center;
  font-size: 26px;
  color: #fff;
}

.board {
  width: 392px;
  margin: 0 auto;
  border: 1px solid #000;
  position: relative;

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  .gomokuBg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: brightness(70%);
  }
}

.row {
  display: flex;
  position: relative;

  &:last-child {
    .shu {
      display: none;
    }
  }

  .heng {
    position: absolute;
    left: 13px;
    top: 50%;
    width: calc(100% - 26px);
    height: 1px;
    transform: translateY(-50%);
    background: #000;
  }

  .shu {
    position: absolute;
    top: 13px;
    left: 50%;
    width: 1px;
    height: 100%;
    transform: translateX(-50%);
    background: #000;
  }
}

.cell {
  width: 26px;
  height: 26px;
  /* background-color: #fff; */
  cursor: pointer;
  position: relative;

  .qi {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;

    &:hover {
      background-color: var(--color);
      opacity: 0.7;
    }
  }
}

.black {
  background-color: #000 !important;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 1);

  &:hover {
    opacity: 1 !important;
  }
}

.black::before {
  content: '';
  position: absolute;
  width: 11px;
  height: 9px;
  background-color: #ffffff75;
  border-radius: 50%;
  top: 3px;
  left: 2px;
  transform: rotate(142deg);
  filter: blur(2px);
}

.white {
  background-color: white;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px rgb(178 178 178);

  &:hover {
    opacity: 1 !important;
  }
}

.white::before {
  content: '';
  position: absolute;
  width: 11px;
  height: 25px;
  background-color: rgba(171, 171, 171, 0.46);
  border-radius: 50%;
  top: 5px;
  left: 9px;
  transform: rotate(42deg);
  filter: blur(2px);
}

.left {
  padding: 0 10px;
  width: 150px;
  height: 100%;
}

.middle {
  width: calc(100% - 300px);
  background: #3b3b3b9c;
  border-radius: 10px;
}

.right {
  width: 150px;
  height: 100%;
}

.room {
  width: 100%;
  max-width: 800px;
  height: 660px;
  padding: 20px;
  display: flex;
  background: #1e1e1e;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}
</style>