<template>
  <div class="swiper-container" ref="cur">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="carousel in list" :key="carousel.id">
        <img :src="carousel.imgUrl" />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div v-show="pagination" class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div v-show="button" class="swiper-button-prev"></div>
    <div v-show="button" class="swiper-button-next"></div>
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28"
      preserveAspectRatio="none" shape-rendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#f9f9f9"></use>
      </g>
    </svg>
  </div>
</template>

<script>
//引入Swiper
import Swiper from "swiper";
export default {
  name: "Carousel",
  props: {
    list: {
      type: Array,
      default: []
    },
    pagination: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    list: {
      //为什么即使监听到数据变化了，还需要放入$nextTick？因为v-for渲染需要时间，在没有渲染完成之前，不能初始化swiper
      immediate: true,
      handler() {
        //只能监听到数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
        this.$nextTick(() => {
          var mySwiper = new Swiper(this.$refs.cur, {
            loop: this.loop,
            autoplay: {
              //自动切换
              delay: 3000,
              stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
              disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
            },
            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              //点击小球的时候也切换图片
              clickable: true,
            },
            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
@media (max-width:1100px) {
  .swiper-container {
    height: 300px !important;
  }
}

.swiper-container {
  width: 100%;
  height: 500px;
  position: relative;

  .waves {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 90px;
    width: 100%;
    z-index: 11;

    .parallax use {
      animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    .parallax use:nth-child(1) {
      // 延时两秒执行
      animation-delay: -2s;
      // 7秒执行结束
      animation-duration: 7s;
    }

    .parallax use:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
    }

    .parallax use:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
    }

    .parallax use:nth-child(4) {
      animation-delay: -5s;
      animation-duration: 20s;
    }
  }

  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@media (max-width: 768px) {
  .swiper-container {
    height: 300px !important;
  }

  .waves {
    height: 40px !important;
    min-height: 40px !important;
  }
}
</style>
