<template>
  <div class=''>
    <iframe class="iframe-box" v-if="qrCodeUrl" :src="qrCodeUrl"></iframe>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      qrCodeUrl: '',
      uuid: '',
      Interval: null
    };
  },
  created() {
    this.$http.get('/getCode').then(res => {
      if (res.status === 1) {
        this.qrCodeUrl = res.result.url
        this.uuid = res.result.uuid
        this.Interval = setInterval(v => {
          this.isLogin()
        }, 1000)
      } else {
        this.$message.error(res.message)
      }
    })
  },
  mounted() {

  },
  methods: {
    isLogin() {
      this.$http.get('/isLogin', {
        params: {
          uuid: this.uuid
        }
      }).then(res => {
        if (res.status == 1) {
          clearInterval(this.Interval)
          this.Interval = null
          localStorage.setItem('user', res.userInfo)
        }
      })
    }
  },
}
</script>
<style lang='scss' scoped>
.iframe-url {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
}

.iframe-box {
  width: 450px;
  height: 450px;
  margin: 10px auto;
}
</style>