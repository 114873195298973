var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"data-box"},[_c('router-view'),_c('div',{staticClass:"home-con"},[_c('div',{staticClass:"left box-shadow"},[_c('tip'),_vm._l((6),function(item){return _c('div',{key:item,on:{"click":_vm.goArticle}},[_c('coolCard',{attrs:{"border":item !== 6,"src":require('@/assets/img/abc.png')}})],1)})],2),_c('div',{staticClass:"right"},[_c('div',{staticClass:"home-box box-shadow"},[_c('img',{staticClass:"bg10-2",attrs:{"src":require("@/assets/bg10-2.png"),"alt":""}}),_vm._m(0),_c('div',{staticClass:"text"},[_vm._v("我坚信，它是最好用的博客平台")]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-item margin blue",on:{"click":_vm.goPage}},[_c('span',{staticClass:"yellow fa fa-hand-o-right"}),_c('span',{staticClass:"box-text"},[_vm._v("聊天娱乐")])]),_c('div',{staticClass:"round"}),_c('div',{staticClass:"box-item green"},[_c('span',{staticClass:"yellow fa fa-hand-o-left"}),_c('span',{staticClass:"box-text",on:{"click":_vm.msgBox}},[_vm._v("朋友圈")])])])]),_c('div',{staticClass:"Hot-article box-shadow"},[_c('tip'),_vm._m(1),_vm._m(2),_vm._m(3)],1),_c('div',{staticClass:"Hot-article Label-field box-shadow"},[_c('tip'),_vm._m(4),_vm._m(5)],1)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-text"},[_c('span',[_vm._v("码农日志")]),_c('img',{attrs:{"src":require("@/assets/badge.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hot-title"},[_c('span',{staticClass:"fa fa-th-large"}),_c('span',{},[_vm._v("热门文章")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article hove"},[_c('span',{staticClass:"fa fa-chevron-right"}),_c('span',[_vm._v("Java 编程练习500题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article hove"},[_c('span',{staticClass:"fa fa-chevron-right"}),_c('span',[_vm._v("Java 编程练习500题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hot-title"},[_c('span',{staticClass:"fa fa-tags"}),_c('span',{},[_vm._v("标签栏")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"tag"},[_vm._v("Java")]),_c('div',{staticClass:"tag"},[_vm._v("Vue")]),_c('div',{staticClass:"tag"},[_vm._v("MySQL")]),_c('div',{staticClass:"tag"},[_vm._v("MongoDB")])])
}]

export { render, staticRenderFns }