<template>
  <div style="position: fixed;left: 0;top: 0;">
    <div class="snow" v-for="item in 100" :key="item">❅</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {
    // 雪花字符 ❄❉❅❆✻✼❇❈❊✥✺

  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.snow {
  position: absolute;
  width: 10px;
  height: 10px;
  color: #fff;
  user-select: none;
}

@keyframes move1 {
  0% {
    // top: 0;
    // left: var(--left-ini);
    transform: translate(var(--left-ini), -5vh) scale(var(--size));
  }

  100% {
    // top: 100vh;
    // left: var(--left-end);
    transform: translate(var(--left-end), 100vh);
  }
}

@for $i from 1 through 100 {
  .snow:nth-child(#{$i}) {
    --left-ini: #{random(20) - 10}vw;
    --left-end: #{random(20) - 10}vw;
    --size: #{random(1) - 0.2};
    // top: -5vh; // 不用top的原因，就是为了不使初始状态时，空间的某块行区域是空白的，不好看，直接用y位移就可以根据时间的随机性，让空间的同时刻每一行都能含有雪花
    left: #{random(100)}vw; // 从原来的位置左右移动一点点距离，如果直接使用left当位移点，移动的跨幅太大了，不好看
    width: #{random() + 5}px;
    height: #{random() + 5}px;
    border-radius: 50%;
    opacity: #{random()};
    animation: move1 #{8 + random(20)}s linear -#{random(10)}s infinite; // 用了延时，就是为了能有一运行代码就能提前有雪花出现在页面上
  }
}
</style>