<template>
  <div class='navItem hove' @click.stop="goPage(item)">
    <span class="fa fa-home"></span>
    <span class="label">{{ item.name }}</span>
    <span class="line"></span>
    <div class="children-box" v-if="item.children && item.children.length > 0">
      <div class="children" @click.stop="goPage(ite)" v-for="(ite, index) in item.children" :key="index">
        <div class="triangle"></div>
        <div class="itema hove">
          <div class="wline"></div>
          <div>{{ ite.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    item: {
      type: Object,
      default: {
        name: '',
        children: []
      }
    }
  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {
    goPage(item) {
      if (this.$route.path == item.path) return
      this.$router.push(item.path)
    }
  },
}
</script>
<style lang='scss' scoped>
.navItem {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .children-box {
    .itema {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      color: #000;

      &:hover {
        .wline {
          width: 5px;
        }
      }

      .wline {
        width: 0;
        height: 2px;
        margin-right: 4px;
        background: #539dfd;
        transition: all .2s;
      }
    }

    .children {
      margin-top: 10px;
      background: #fff;
      position: relative;
      border-radius: 4px;
    }

    position: absolute;
    left: 50%;
    bottom: 0;
    width: 150%;
    height: 50px;
    transform: translateX(-50%) translateY(100%);
    z-index: 99;
    display: none;

    .triangle {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%) translateY(-100%);
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-bottom-color: #fff;
    }
  }

  &:hover {
    .line {
      width: 100%;
    }

    .children-box {
      display: block;
    }
  }

  .line {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #539dfd;
    transition: all .2s;
  }

  .label {
    font-size: 16px;
    margin-left: 0.3rem;
  }
}
</style>