import Vue from 'vue';
import dom from './index.vue' // 引入组件，方便继承
let messageBoxDom = Vue.extend(dom); // 引入一个message构造器，方便new之

const messageBox = (msg) => {
  return new Promise((resolve, reject) => {
    const cancel = () => {
      reject()
    }
    const confirm = () => {
      resolve()
    }
    let instance = new messageBoxDom({ // 实例化一个组件实例
      propsData: { // propsData传参，
        msg,
        confirm: confirm,
        cancel: cancel
      },
    });
    instance.$mount(); // 实例组件挂载
    document.body.appendChild(instance.$el); // 把这个组件实例的dom元素，追加到document文档中
    instance.isShowMyMessage = true;
  })
}

export default messageBox // 暴露出去
Vue.prototype.$messageBox = messageBox;