<template>
  <div class="emoji-body">
    <div class="emoji-box">
      <div class="emoji-item" v-for="(item, index) in emojiList" :key="index" @click.stop="clickItem(item)">
        <span>{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const emojiJson = require("./emoji.json");
export default {
  data() {
    return {
      emojiList: []
    }
  },
  created() {
    this.emojiList = emojiJson.data.split(',');
  },
  methods: {
    clickItem(item) {
      this.$emit('change', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.emoji-body {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -105%);
  filter: drop-shadow(0 0 1px #a2a2a2);

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border: 6px solid #494848;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    z-index: 11;
  }
}

.emoji-box {
  width: 400px;
  height: 200px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  background-color: #494848;
  border-radius: 10px;
  overflow-y: auto;

  .emoji-item {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &:hover {
      background-color: #d8d8d8;

      span {
        transform: scale(1.3);
      }
    }
  }
}
</style>