<template>
  <div id="app">
    <router-view v-wechat-title='$route.meta.title' />
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      timer: null
    }
  },
  mounted() {

  },
  methods: {

  },
  created() {
    if (process.env.NODE_ENV === 'production') {
      // this.timer = setInterval(() => {
      //   const originalLog = console.log;
      //   console.log = function () {
      //     alert('Console is open');
      //     originalLog.apply(console, arguments);
      //   };
      //   if (this.$route.path == '/blank') return
      //   this.$router.replace('/blank')
      // }, 100)
      document.onkeydown = () => {
        //禁用F12
        if (window.event && window.event.keyCode == 123) {
          return false;
          //禁用ctrl+shift+i,
        } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
          return false;
          //屏蔽Shift+F10
        } else if (window.event.shiftKey && window.event.keyCode == 121) {
          return false;
        }
      };
      window.addEventListener('contextmenu', e => {
        return e.returnValue = false
      })
    }
  },
  destroyed() {

  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "阿里妈妈东方大楷 Regular";
  font-weight: 400;
  src: url("http://at.alicdn.com/wf/webfont/LfgSx1eKUN8F/agWhQU98VFaJdGXDYpj7l.woff2") format("woff2"),
    url("http://at.alicdn.com/wf/webfont/LfgSx1eKUN8F/yJoNz30obJtINZZoUQvB7.woff") format("woff");
  font-display: swap;
}

.box-shadow {
  box-shadow: 0 0 3px #d1d0d0;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4);
  z-index: 1000;
}

.hove {
  cursor: pointer;
}

.hove:hover {
  color: #539dfd !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}
</style>
