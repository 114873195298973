<template>
  <div class="nav" :style="{ background: background }" :class="className">
    <div class="nav-box">
      <div class="nav-left">
        <div class="fa fa-list-ul">
        </div>
        <div class="nav-logo">
          <div class="nav-logo-box" @click="goHome">
            <img class="logo" src="@/assets/logo.png" alt="" srcset="">
          </div>
        </div>
        <div class="nav-center">
          <div class="navItem-box" v-for="(item, index) in navList" :key="index">
            <navItem :item="item"></navItem>
          </div>
        </div>
      </div>
      <div class="nav-right" v-if="!user" @mouseover="showLogin = true" @mouseleave="showLogin = false">
        <span class="fa fa-user-circle-o"></span>
        <div class="login-box" v-if="showLogin">
          <div style="height: 20px;"></div>
          <div class="item-list">
            <div class="san"></div>
            <div class="item" v-for="(item, index) in loginTypeList" :key="index" @click="goLogin(item)">
              <span :class="item.class"></span>
              <span class="tete">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-right" v-if="user" @click="clickUser">
        <img :src="user.avatar" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import navItem from '@/components/navItem.vue'
export default {
  components: {
    navItem
  },
  props: {
    background: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    navList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      showLogin: false,
      user: '',
      loginTypeList: [
        { class: 'fa fa-qrcode', name: '扫码登录', type: 'code' },
        { class: 'fa fa-envelope-o', name: '邮箱登录', type: 'mail' },
        { class: 'fa fa-lock', name: '密码登录', type: 'password' },
      ]
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user')) || ''
    if (this.user && this.user.avatar.indexOf('http') == -1) {
      this.user.avatar = require(`@/assets/avatar/${this.user.avatar}`)
    }
  },
  methods: {
    goHome() {
      if (this.$route.path == '/') return
      this.$router.push('/')
    },
    goLogin(item) {
      if (item.type == 'code') return this.$message.error('待开发')
      if (item.type == 'mail') this.$router.push({ path: '/login', query: { type: 'mail' } })
      else this.$router.push('/login')
    },
    clickUser() {
      this.$router.push('/login')
    }
  },
}
</script>
<style lang='scss' scoped>
.nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
  transition: all .2s;
  user-select: none;

  .nav-box {
    max-width: 1360px;
    margin: 0 auto;
    padding: 0 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    transition: all .2s;

    .nav-left {
      display: flex;
      align-items: center;
      height: 100%;

      .nav-center {
        margin-left: 100px;
        height: 100%;
        display: flex;
        align-items: center;

        .navItem-box {
          height: 100%;
          margin-right: 30px;
        }
      }
    }

    .nav-logo:hover {

      .nav-logo-box {
        transform: scale(0.9);
      }
    }

    .nav-logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .2s;

      .nav-logo-box {
        display: flex;
        align-items: center;
      }

      .blog {
        width: 72px;
        height: 25px;
      }

      .logo {
        width: 45px;
        height: 45px;
        margin-right: 6px;
        border-radius: 50%;
      }
    }
  }
}

.black {
  background: #fff !important;

  .nav-box {
    color: #000 !important;
  }
}

.nav-right {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;

  .login-box {
    position: absolute;
    right: 0;
    bottom: 10px;
    transform: translateY(100%);
    width: 100px;
    z-index: 1;

    .item-list {
      padding: 6px 0;
      background: #fff;
      color: #000;
      text-align: center;
      box-shadow: 1px 1px 1px #9e9e9e;
      position: relative;

      .san {
        width: 0;
        height: 0;
        border: 6px solid #fff;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        position: absolute;
        top: 0;
        right: 10px;
        transform: translateY(-100%);
      }

      .item {
        padding: 6px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background: #539dfd;

          span {
            color: #fff !important;
          }
        }

        .fa {
          width: 20px;
          font-size: 16px !important;
        }

        .tete {
          font-size: 14px;
        }
      }


    }
  }

  .fa-user-circle-o {
    font-size: 20px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &:hover .fa-user-circle-o {
    color: #539dfd !important;
  }
}

.fa-list-ul {
  font-size: 22px;
  display: none;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .nav-center {
    display: none !important;
  }

  .fa-list-ul {
    display: block;
  }

  .nav-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>