<template>
  <div class='alert'>
    <div class="mask" @click="close"></div>
    <div class="con">
      <div class="title">
        <span>{{ title }}</span>
        <img @click="close" src="@/assets/img/close.png" alt="" srcset="">
      </div>
      <slot name="content"></slot>
      <div class="bottom">
        <div class="cancel" @click="close">取消</div>
        <div class="confirm" @click="confirm">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    close() {
      this.$emit('close')
    }
  },
}
</script>
<style lang='scss' scoped>
.title {
  height: 40px;
  box-shadow: 0 1px 1px #979797;
  position: relative;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 16px;
    transform: translate(-50%, -50%);
  }

  img {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.con {
  position: fixed;
  left: 50%;
  top: 50%;
  min-width: 400px;
  height: 300px;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 0 3px #cecece;
  border-radius: 5px;
  z-index: 112;
}

.bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: right;
  box-shadow: 0 -1px 1px #acacac;

  .cancel {
    color: #3E4458;
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #3E4458;
  }

  .confirm {
    color: #EDF1FF;
    background: #3E4458;
    border-radius: 3px;
    border: 1px solid #14244A;
  }

  div {
    margin-right: 20px;
    padding: 4px 20px;
    cursor: pointer;
  }
}

.mask {
  background: rgba($color: #000000, $alpha: .5);
  cursor: pointer;
}

.alert,
.mask {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
}

* {
  user-select: none;
}
</style>