<template>
  <div v-show="isShowMyMessage">
    <div class="mask"></div>
    <div class='msg-box'>
      <span>{{ msg }}</span>
      <div class="bottom">
        <div class="cancel" @click="clickCancel">取消</div>
        <div class="confirm" @click="clickConfirm">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'messageBox',
  props: {
    msg: {
      type: String,
      default: '成功'
    },
    cancel: {
      type: Function
    },
    confirm: {
      type: Function
    }
  },
  data() {
    return {
      isShowMyMessage: false
    };
  },
  mounted() {

  },
  methods: {
    clickConfirm() {
      this.confirm()
      this.destroyDom()
    },
    clickCancel() {
      this.cancel()
      this.destroyDom()
    },
    destroyDom() {
      // 移除dom
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    }
  }
}
</script>
<style lang='scss' scoped>
* {
  box-sizing: border-box;
}

.msg-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  min-width: 200px;
  display: flex;
  align-items: center;
  z-index: 99999;
  border-radius: 4px;
  transition: opacity 0.3s, transform 0.4s;
  background: #000;
  color: #fff;
}

@keyframes move {
  0% {}
}
</style>