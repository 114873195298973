<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" width="100%" height="100%"
    shape-rendering="auto">
    <g data-idx="1">
      <g transform="matrix(1,0,0,1,20,50)" data-idx="2">
        <circle class="circle1" fill="#e15b64" r="6" cy="0" cx="0" data-idx="3"
          transform="matrix(0.03845442831516266,0,0,0.03845442831516266,0,0)">
        </circle>
      </g>
      <g transform="matrix(1,0,0,1,40,50)" data-idx="5">
        <circle class="circle2" fill="#f8b26a" r="6" cy="0" cx="0" data-idx="6"
          transform="matrix(0.05991023778915405,0,0,0.05991023778915405,0,0)">
        </circle>
      </g>
      <g transform="matrix(1,0,0,1,60,50)" data-idx="8">
        <circle class="circle3" fill="#abbd81" r="6" cy="0" cx="0" data-idx="9"
          transform="matrix(0.3448406457901001,0,0,0.3448406457901001,0,0)">
        </circle>
      </g>
      <g transform="matrix(1,0,0,1,80,50)" data-idx="11">
        <circle class="circle4" fill="#81a3bd" r="6" cy="0" cx="0" data-idx="12"
          transform="matrix(0.6956265568733215,0,0,0.6956265568733215,0,0)">
        </circle>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>
@keyframes scale {

  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

svg {
  display: block;
  margin: 0 auto;
}

circle {
  animation: scale 1s linear infinite;
}

.circle1 {
  animation-delay: 0s;
}

.circle2 {
  animation-delay: 0.2s;
}

.circle3 {
  animation-delay: 0.4s;
}

.circle4 {
  animation-delay: 0.6s;
}
</style>