<template>
  <div class="Editor-box">
    <!-- 工具栏 -->
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
    <!-- 编辑器 -->
    <Editor class="Editor" :defaultConfig="editorConfig" v-model="html" @onChange="onChange" @onCreated="onCreated" />
  </div>
</template>
 
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "editorVue",
  components: { Editor, Toolbar },
  props: {
    content: {
      type: String,
      default: '',
    },
    readOnlys: { // 只读
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容",
        // autoFocus: false,
        // readOnly: true, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            customUpload: this.uploaadImg
          },
        },
      },
    };
  },
  watch: {
    readOnlys: {
      handler(newV) {
        if (newV) {
          this.editor.disable()  // 只读模式
        }
      }
    },
  },
  methods: {
    uploaadImg(file, insertFn) {
      this.$emit('uploadImg', file, insertFn)
    },
    onCreated(editor) {
      this.editor = Object.seal(editor);
    },
    onChange(editor) {
      this.$emit('changeData', this.html)
    },
  },
  created() {
    this.html = this.content;
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor
  },
};
</script>
 
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
.Editor {
  max-width: 1360px;
  height: 600px;
  margin: 10px auto 0;
  padding: 0 10px;
  overflow-y: hidden;
  border-radius: 5px;
}

.Editor-box {
  border-top: 1px solid #ccc;
}
</style>
