<template>
  <alert>
    <div slot="content">
      ceshi
    </div>
  </alert>
</template>
<script>
import alert from './alert.vue';
export default {
  components: {
    alert
  },
  props: {

  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped></style>