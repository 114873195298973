<template>
  <div class="home">
    <navBox :navList="navList" :className="className"></navBox>
    <div class="home-body-box">
      <Carousel :list="list"></Carousel>
      <div class="home-body">
        <router-view />
      </div>
    </div>
    <div class="bottom-box">
      <div class="bottom box-shadow">
        <div class="bot-title">
          <span class="fa fa-paperclip"></span>
          <span>友情链接</span>
        </div>
        <div class="paperclip-box">
          <div class="paperclip hove" @click="$message.success('1')">Gitee</div>
          <div class="paperclip hove" @click="$message.error('1')">Gitee</div>
          <div class="paperclip hove" @click="$message.info('1')">Gitee</div>
          <div class="paperclip hove" @click="$message.waring('1')">Gitee</div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright-con">
        <div>
          <div><a href="https://beian.miit.gov.cn">ICP主体备案号: 豫ICP备2023017407号</a></div>
          <div>ICP网站备案号: 豫ICP备2023017407号-1</div>
        </div>
      </div>
    </div>
    <!-- <loginAlert></loginAlert> -->
  </div>
</template>

<script>
import navBox from '@/components/nav.vue'
import loginAlert from '@/components/loginAlert.vue'
export default {
  components: {
    navBox,
    loginAlert
  },
  data() {
    return {
      className: '',
      user: '',
      list: [
        { imgUrl: require('@/assets/img/rotographa.jpg') },
        { imgUrl: require('@/assets/img/rotographb.jpg') },
        { imgUrl: require('@/assets/img/rotographc.jpg') },
        { imgUrl: require('@/assets/img/rotographd.jpg') }
      ],
      navList: [
        { name: '首页', path: '/' }
      ]
    }
  },
  created() {

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop == 0) this.className = ''
      if (scrollTop > 10) {
        this.className = 'black'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home-body {
  max-width: 1360px;
  margin: 0 auto;
  padding: 10px 20px;
}

.copyright {
  padding: 20px 0;
  margin-top: 20px;
  box-shadow: 0 -1px 3px #d1d0d0;

  .copyright-con {
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bottom-box {
  max-width: 1360px;
  margin: 10px auto 0;
  padding: 10px 20px 0;

  .bottom {
    padding: 10px 10px 0;
  }

  .paperclip-box {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;

    .paperclip {
      padding: 3px 6px;
      margin: 0 10px 10px 0;
      background: #c5f0ff;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  .bot-title {
    padding-bottom: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #333333;
    border-bottom: 1px solid #dfdfdf;

    .fa {
      font-size: 16px;
      margin-right: 3px;
    }
  }
}

@media (max-width: 1100px) {

  .fa-list-ul {
    display: block;
  }

}

.home {
  min-height: 100vh;
  // height: 100vh;
  // overflow-y: auto;
  background: #f9f9f9;
}
</style>
