import { render, staticRenderFns } from "./index.vue?vue&type=template&id=761cdc23&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/view.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./index.vue?vue&type=style&index=2&id=761cdc23&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "761cdc23",
  null
  
)

export default component.exports