<template>
  <div class="bg">
    <div>
      <div class="page-box">
        <div class="login-box" :class="isLogin ? 'transform' : 'transformX'">
          <div class="home hove" @click="$router.push('/')">
            <span class="fa fa-home"></span>
            <span class="home-span">首 页</span>
          </div>
          <div class="input-box">
            <div class="input">
              <span class="fa fa-user-o"></span>
              <input type="text" v-model="userName">
            </div>
            <div class="input margin">
              <span class="fa fa-lock"></span>
              <input type="text" v-model="passWord">
            </div>
            <div class="btn">
              <span class="hove" @click="isLogin = !isLogin">还没有账号，去注册~ </span>
              <div class="login-btn" @click="login">登 录</div>
            </div>
          </div>
        </div>
        <div class="login-box" :class="isLogin ? 'transformo' : 'transformXX'">
          <div class="home hove" @click="$router.push('/')">
            <span class="fa fa-home"></span>
            <span class="home-span">首 页</span>
          </div>
          <div class="input-box margin">
            <div class="input">
              <span class="fa fa-user-o"></span>
              <input type="text" v-model="userName">
            </div>
            <div class="input margin">
              <span class="fa fa-lock"></span>
              <input type="text" v-model="passWord">
            </div>
            <div class="input margin">
              <span class="fa fa-envelope-o"></span>
              <input type="text" v-model="email">
            </div>
            <div class="input margin code">
              <input type="number" v-model="code" @input="inputCode">
              <span class="code-btn" :class="Interval ? 'disabled' : ''" @click="getCode">{{ Interval ? timeNum :
                '获取验证码'
                }}</span>
            </div>
            <div class="btn">
              <span class="hove" @click="isLogin = !isLogin">已 有 账 号，去 登 录</span>
              <div class="login-btn" @click="reg">注 册</div>
            </div>
          </div>
        </div>
        <div class="blur_box">
          <div class="rgba"></div><!-- 写在这其实和blur_box:before效果相同，但已经设置过blur_box:before了 -->
          <div class="content_text">
            <h2>{{ isLogin ? '登 录' : '注 册' }}</h2>
            <div class="p">
              欢 迎 使 用 码 农 日 志 博 客，我 相 信 这 将 会 是 最 好 用 的 博 客
            </div>
          </div>
        </div>
      </div>
      <div class="avatar" v-if="!isLogin">
        <img :class="item.active ? 'active' : ''" v-for="(item, index) in avatarList" :key="index" :src="item.url"
          alt="" @click="selectAvatar(item)">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      userName: 'xiaoyu',
      passWord: '123456',
      email: '1461382590@qq.com',
      isLogin: true,
      code: '',
      timeNum: 60,
      Interval: null,
      showAlert: false,
      avatarList: [
        { url: require('@/assets/avatar/a.png'), active: false, img: 'a.png' },
        { url: require('@/assets/avatar/b.png'), active: false, img: 'b.png' },
        { url: require('@/assets/avatar/c.png'), active: false, img: 'c.png' },
        { url: require('@/assets/avatar/d.png'), active: false, img: 'd.png' },
        { url: require('@/assets/avatar/e.png'), active: false, img: 'e.png' },
        { url: require('@/assets/avatar/f.png'), active: false, img: 'f.png' },
        { url: require('@/assets/avatar/g.png'), active: false, img: 'g.png' },
        { url: require('@/assets/avatar/h.png'), active: false, img: 'h.png' },
        { url: require('@/assets/avatar/i.png'), active: false, img: 'i.png' },
        { url: require('@/assets/avatar/j.png'), active: false, img: 'g.png' },
        { url: require('@/assets/avatar/k.png'), active: false, img: 'k.png' },
        { url: require('@/assets/avatar/l.png'), active: false, img: 'l.png' },
        { url: require('@/assets/avatar/m.png'), active: false, img: 'm.png' },
        { url: require('@/assets/avatar/n.png'), active: false, img: 'n.png' },
        { url: require('@/assets/avatar/o.png'), active: false, img: 'o.png' },
        { url: require('@/assets/avatar/p.png'), active: false, img: 'p.png' },
        { url: require('@/assets/avatar/q.png'), active: false, img: 'q.png' },
        { url: require('@/assets/avatar/r.png'), active: false, img: 'r.png' },
        { url: require('@/assets/avatar/s.png'), active: false, img: 's.png' },
        { url: require('@/assets/avatar/t.png'), active: false, img: 't.png' },
        { url: require('@/assets/avatar/u.png'), active: false, img: 'u.png' },
      ],
      focusAvatar: {}
    };
  },
  created() {

  },
  methods: {
    selectAvatar(item) {
      this.avatarList.forEach(i => i.active = false)
      this.focusAvatar = item
      item.active = true
    },
    getCode() {
      if (this.Interval) return
      if (!this.userName) return this.$message.error('请填写用户名')
      if (!this.passWord) return this.$message.error('请填写密码')
      if (!this.email) return this.$message.error('请填写邮箱')
      this.$http.post('/user/email', { email: this.email, userName: this.userName }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.Interval = setInterval(v => {
            if (this.timeNum > 0) {
              this.timeNum--
            } else {
              clearInterval(this.Interval)
              this.Interval = null
              this.timeNum = 60
            }
          }, 1000)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    inputCode() {
      if (this.code.length > 6) {
        this.code = this.code.substring(0, 6)
      }
    },
    reg() {
      if (!this.userName) return this.$message.error('请填写用户名')
      if (!this.passWord) return this.$message.error('请填写密码')
      if (!this.email) return this.$message.error('请填写邮箱')
      if (!this.code) return this.$message.error('请填写验证码')
      if (!this.focusAvatar.url) return this.$message.error('请选择你的头像')
      let param = {
        userName: this.userName,
        passWord: this.$md5(this.passWord),
        email: this.email,
        avatar: this.focusAvatar.img,
        code: this.code
      }
      this.$http.post('/user/reg', param).then(res => {
        if (res.status === 1) {
          this.$message.success('注册成功')
          this.isLogin = !this.isLogin
        } else {
          this.$message.error(res.message)
        }
      })
    },
    login() {
      if (!this.userName) return this.$message.error('请填写用户名')
      if (!this.passWord) return this.$message.error('请填写密码')
      let param = {
        userName: this.userName,
        passWord: this.$md5(this.passWord)
      }
      this.$http.post('/user/login', param).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          if (res.result.avatar.indexOf('http') == -1) {
            res.result.avatarUrl = require(`@/assets/avatar/${res.result.avatar}`)
          }
          this.$tool.setStorage('user', res.result)
          this.$router.push('/')
        } else {
          this.$message.error(res.message)
        }
      })
    }
  },
}
</script>
<style lang='scss' scoped>
.avatar {
  margin-top: 15px;
  padding: 0 15px;
  width: 500px;
  height: 70px;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: nowrap;
  overflow-x: auto;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px;

  .active {
    box-shadow: 0 0 4px 0 #ffffff;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.alert-content {
  padding: 15px;
}

.disabled {
  background: #76b6e4 !important;
  cursor: not-allowed !important;
}

@media (max-width:530px) {
  .blur_box {
    display: none;
  }

  .transformXX {
    transform: translateX(0) !important;
  }

  .page-box {
    justify-content: center;
  }

  .transformo {
    display: none !important;
  }

  .transformX {
    display: none !important;
  }
}

.transform {
  transform: translateX(0);
}

.transformo {
  transform: translateX(0);
  opacity: 0;
}

.transformXX {
  transform: translateX(-100%);
}

.transformX {
  transform: translateX(100%);
  opacity: 0;
}

.btn {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 14px;
    cursor: pointer;
  }

  .login-btn {
    padding: 3px 6px;
    color: #ffffff;
    background: #278fda;
    border-radius: 4px;
    cursor: pointer;
  }
}

.margin {
  margin-top: 10px;
}

.page-box {
  display: flex;
  position: relative;
  width: 480px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;

  .home {
    position: absolute;
    left: 0;
    top: 0;
    padding: 6px 8px;
    cursor: pointer;
    z-index: 13;

    .home-span {
      margin-left: 4px;
      font-size: 14px;
    }
  }

  .login-box {
    width: 240px;
    height: 280px;
    background-color: rgba(255, 255, 255, 0.7);
    position: relative;
    z-index: 11;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    transition: all .4s;

    .input-box {
      width: 100%;
    }

    .code {
      height: 29px;

      input {
        padding: 0 !important;
      }

      .code-btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 4px 10px;
        color: #ffffff;
        background: #278fda;
        border-radius: 4px;
        cursor: pointer;
        z-index: 11;
      }
    }

    .input {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #adadad;
      position: relative;

      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0 0 0 35px;
        font-size: 14px;
        border: none;
        background: transparent;
        z-index: 10;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.bg {
  background: url('@/assets/preview.jpg') no-repeat center center fixed;
  /* 与下面的blur_box:before中的background设置一样 */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.blur_box {
  z-index: 12;
  /* 为不影响内容显示必须为最高层 */
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 240px;
  height: 280px;
  transition: all .4s;
}

.blur_box:before {
  content: "";
  /* 必须包括 */
  position: absolute;
  /* 固定模糊层位置 */
  width: 300%;
  height: 300%;
  left: -100%;
  /* 回调模糊层位置 */
  top: -100%;
  /* 回调模糊层位置 */
  background: url('@/assets/preview.jpg') no-repeat center center fixed;
  background-size: cover;
  /* 与上面的bg中的background设置一样 */
  filter: blur(20px);
  /* 值越大越模糊 */
  z-index: -2;
  /* 模糊层在最下面 */
}

.rgba {
  background-color: rgba(255, 255, 255, 0.2);
  /* 为文字更好显示，将背景颜色加深 */
  position: absolute;
  /* 固定半透明色层位置 */
  width: 100%;
  height: 100%;
  z-index: -1;
  /* 中间是rgba半透明色层 */
}

.content_text {
  padding: 20px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);

  .p {
    padding: 0 15px;
    margin-top: 50px;
  }
}

.bbox {
  width: 400px;
  height: 400px;
  position: relative;

  .men {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .ring:hover+.men .left {
    transform: translateX(-50%);
  }

  .ring:hover {
    opacity: 0;
  }

  .ring:hover+.men .right {
    transform: translateX(50%);
  }

  .ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 2;
    background: #278fda;
    cursor: pointer;
    transition: all .3s;
  }

  .left,
  .right {
    width: 50%;
    height: 100%;
    background: #bbe3ff;
    transition: all .8s;
  }

  .right {
    background: #7dc1f1;
  }
}

.box-a {
  margin-top: 50px;
}

.title {
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
  font-family: serif;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 2px;
  position: relative;
  z-index: 1;
  user-select: none;
}

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('@/assets/preview.jpg') no-repeat;
  background-size: cover;
}

.box {
  width: 200px;
  height: 200px;
  position: relative;
  background: white;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(1rem);
  border-radius: 5px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  overflow: hidden;

  // .ball {
  //   width: 900px;
  //   height: 900px;
  //   position: absolute;
  //   bottom: 300px;
  //   left: -260px;
  //   border-radius: 43%;
  //   background-color: deepskyblue;
  //   filter: opacity(.3);
  //   animation: rotation linear infinite;
  // }

  // .ball:nth-child(1) {
  //   animation-duration: 5s;
  // }

  // .ball:nth-child(2) {
  //   animation-duration: 7s;
  // }

  // .ball:nth-child(3) {
  //   animation-duration: 9s;
  // }
}

// @keyframes rotation {
//   from {
//     transform: rotate(360deg);
//   }
// }</style>