<template>
  <div class='ios'>
    <div class="box">
      <div class="xiazai" @click="xiazai">获取UDID</div>
      <div class="udid" v-if="dataObj.UDID">
        <span>你的UDID：</span>
        <span>{{ dataObj.UDID }}</span>
      </div>
      <div class="copy xiazai" v-if="dataObj.UDID" @click="copy">复制UDID</div>
    </div>
  </div>
</template>
<script>
// /usr/bin/security cms -S -N "iPhone Developer: jiayu liu" -i /Users/xiaoyu/work/blogserver/public/uploads/udid.mobileconifg -o /Users/xiaoyu/work/blogserver/public/uploads/udids.mobileconifg
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      dataObj: {}
    };
  },
  watch: {
    '$route'(to, from) {
      // 路由变化时的处理逻辑
      this.dataObj = this.$route.query.data ? JSON.parse(this.$route.query.data) : {}
    }
  },
  created() {
    this.dataObj = this.$route.query.data ? JSON.parse(this.$route.query.data) : {}
  },
  methods: {
    copy() {
      let input = document.createElement("input");
      document.body.appendChild(input);
      input.value = this.dataObj.UDID; // 此处为需要复制的文本变量
      input.focus();
      input.select();
      try {
        let result = document.execCommand("copy");
        document.body.removeChild(input);
        if (!result) {
          this.$message.error("复制失败");
        } else {
          this.$message.success("复制成功");
        }
      } catch (e) {
        document.body.removeChild(input);
        this.$message.error("当前浏览器不支持复制功能，请检查更新或更换其他浏览器操作");
      }
    },
    xiazai() {
      var xhr = new XMLHttpRequest();
      //GET请求,请求路径url,async(是否异步)
      xhr.open('GET', 'https://api.mnrzi.cn/uploads/udids.mobileconifg', true);
      //设置响应类型为 blob
      xhr.responseType = 'blob';
      //关键部分
      xhr.onload = function (e) {
        //如果请求执行成功
        if (this.status == 200) {
          var originalBlob = this.response;
          var filename = "udid.mobileconfig";//如123.xls
          var reader = new FileReader();
          reader.onload = function (event) {
            // 创建新的 blob 对象，指定 MIME 类型
            var newBlob = new Blob([event.target.result], { type: 'application/x-apple-aspen-config' });
            var url = URL.createObjectURL(newBlob);
            // 创建下载链接并点击下载
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
            // 释放之前创建的 URL 对象
            window.URL.revokeObjectURL(url);
          };
          // 读取原始 blob 内容为 ArrayBuffer
          reader.readAsArrayBuffer(originalBlob);
        }
      };
      //发送请求
      xhr.send();
    }
  }
}
</script>
<style lang='scss' scoped>
.udid {
  margin: 10px 0;
}

.xiazai {
  margin: 0 auto;
  width: 100px;
  text-align: center;
  padding: 10px 0;
  background: #b7b7b7;
  border: 1px solid #efefef;
  cursor: pointer;
}

.ios {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>