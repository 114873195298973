<template>
  <div class='code'>
    <div class="create">
      <div class="flex">
        <span>类型：</span>
        <input type="text" v-model="time">
        <span>（0是永久卡)</span>
      </div>
      <div class="flex">
        <span>数量：</span>
        <input type="text" v-model="codeNum">
      </div>
      <div class="btn" @click="createCode">生成</div>
    </div>
    <div class="list">
      <div class="item">
        <span>卡密</span>
        <span>类型</span>
        <span>状态</span>
        <span>操作</span>
      </div>
      <div class="item" v-for="(item, index) in list" :key="index">
        <span>{{ item.code }}</span>
        <span>{{ item.time == 0 ? '永久卡' : item.time + '天卡' }}</span>
        <span>{{ item.type == 0 ? '未使用' : '已使用' }}</span>
        <span class="del" @click="dleCode(item)">删除</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      list: [],
      time: '',
      codeNum: ''
    };
  },
  created() {
    this.getCode()
  },
  methods: {
    createCode() {
      this.$http.post('/createCode', { time: this.time, num: this.codeNum }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.getCode()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    dleCode(item) {
      this.$http.post('/delCode', { code: item.code }).then(res => {
        if (res.status === 1) {
          this.$message.success('删除成功')
          this.getCode()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getCode() {
      this.$http.post('/getCode').then(res => {
        if (res.status === 1) {
          this.list = res.result
        }
      })
    }
  },
}
</script>
<style lang='scss' scoped>
input {
  width: 60px;
}

.btn {
  width: 70px;
  padding: 4px 0;
  text-align: center;
  color: #fff;
  background: #449af0;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #90bce8;
  }
}

.create {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
}

.del {
  color: #f04444;
  text-decoration: underline;
  cursor: pointer;
}

.item {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #999;

  span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-right: 1px solid #999;

    &:last-child {
      border: none;
    }
  }
}

.list {
  width: 740px;
  user-select: text;
  border: 1px solid #999;

  &:last-child {
    border-bottom: none;
  }
}
</style>