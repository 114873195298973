<template>
  <div class='tip'>
    <div class="yuan red"></div>
    <div class="yuan yellow"></div>
    <div class="yuan blue"></div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>
.tip {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;

  .yuan {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .blue {
    background: #608af2;
  }

  .yellow {
    margin: 0 6px;
    background: #f2c631;
  }

  .red {
    background: #ee2d1c;
  }
}
</style>