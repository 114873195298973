import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuewechattitle from 'vue-wechat-title'
import 'font-awesome/css/font-awesome.min.css';
import tool from './utils/tool'
// 动态激活组件
const files = require.context('./component', true, /\.js$/)
files.keys().forEach(key => {
  const item = files(key)
})

Vue.prototype.$tool = tool
// 引入 axios
import axios from 'axios'
// 引入js-md5
import md5 from 'js-md5'
Vue.prototype.$md5 = md5

// 挂载一个自定义属性$http
Vue.prototype.$http = axios
// 全局配置axios请求根路径(axios.默认配置.请求根路径)
if (process.env.NODE_ENV === 'development') {
  // console.log('开发环境development')
} else if (process.env.NODE_ENV === 'production') {
  // console.log('生产环境production')
}
axios.defaults.baseURL = process.env.VUE_APP_API_URL
// 请求拦截器
axios.interceptors.request.use(req => {
  // let token = sessionStorage.getItem('Auth');//获取页面存储的token
  // if (token) {
  //   req.headers['Auth'] = token;//将token添加至请求头对象的Auth属性中
  // }
  return req;
}, err => {
  return Promise.reject(err);
})
// 响应拦截器
axios.interceptors.response.use(res => {
  return res.data
}, err => {
  return Promise.reject(err);
})

//引入swiper样式
import "swiper/css/swiper.css";
//定义全局组件：在入口文件注册一次之后，在任何组件当中都可以使用
import Carousel from "@/components/Carousel";
import coolCard from "@/components/coolCard.vue";
import tip from "@/components/tip.vue";
import loading from '@/components/loading.vue';

Vue.component("loading", loading);
Vue.component("tip", tip);
Vue.component("Carousel", Carousel);
Vue.component("coolCard", coolCard);

Vue.use(vuewechattitle)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
