
const tool = {
  getStorage: (key) => {
    return JSON.parse(localStorage.getItem(key))
  },
  setStorage: (key, val) => {
    localStorage.setItem(key, JSON.stringify(val))
  }
}

export default tool