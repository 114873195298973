<template>
  <div class="coolCard-box" :class="border ? '' : 'border-no'">
    <div class="line"></div>
    <div class='coolCard'>
      <div class="title-tip">Java</div>
      <img :src="src">
      <div class="mask"></div>
    </div>
    <div class="con">
      <div class="top">
        <div class="top-letft">
          置顶
        </div>
        <div class="hove">Java 编程练习500题</div>
      </div>
      <div class="center hove">请问</div>
      <div class="bottom flex align-center">
        <div class="flex align-center hove logosss">
          <img class="logo" src="@/assets/logo.png" alt="" srcset="">
          <span>码农日志</span>
        </div>
        <div class="logo-tian"></div>
        <div class="flex align-center">
          <span class="hove praise flex align-center">
            <span class="fa fa-thumbs-o-up"></span>
            <span>&nbsp;99+</span>
          </span>
          <span class="hove margin flex align-center">
            <span class="fa fa-fire"></span>
            <span>&nbsp;99+</span>
          </span>
          <span class="hove flex align-center">
            <span class="fa fa-clock-o"></span>
            <span class="time1">&nbsp;2022-12-28</span>
            <span class="time2">&nbsp;2022-12-28 22:52:51</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    src: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>
.border-no {
  border: none !important;
}

.time1 {
  display: none;
}

@media (max-width:530px) {
  .coolCard {
    width: 100px !important;
    height: 60px !important;
    margin-right: 10px !important;
  }

  .title-tip {
    padding: 2px 3px !important;
    font-size: 10px !important;
  }

  .time1 {
    display: block;
  }

  .time2 {
    display: none;
  }

  .margin {
    margin: 0 20px !important;
  }

  .bottom {
    font-size: 12px !important;
  }

  .con {
    width: calc(100% - 110px) !important;

    .top {
      font-size: 16px !important;

      .top-letft {
        font-size: 10px !important;
      }
    }
  }

  .title-tip {
    top: 5px !important;
    right: 5px !important;
  }

  .praise {
    display: none !important;
  }

  .center {
    display: none !important;
  }

  .logosss {
    display: none;
  }

  .logo-tian {
    display: block !important;
  }
}

@media (min-width: 530px) and (max-width: 790px) {
  .coolCard {
    width: 190px !important;
    height: 110px !important;
  }

  .time1 {
    display: block;
  }

  .time2 {
    display: none;
  }

  .praise {
    display: none !important;
  }

  .con {
    width: calc(100% - 210px) !important;
  }

  .logosss {
    display: none;
  }

  .logo-tian {
    display: block !important;
  }
}

.logo-tian {
  display: none;
}

.margin {
  margin: 0 40px;
}

.logo {
  width: 25px;
  height: 25px;
  margin-right: 4px;
}

.con {
  position: relative;
  width: calc(100% - 270px);

  .center {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    font-size: 16px;
  }

  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 15px;
    color: #575757;
    justify-content: space-between;
  }

  .top {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;

    .top-letft {
      padding: 3px 6px;
      margin-right: 6px;
      color: #fff;
      font-size: 13px;
      background: #f14446;
      border-radius: 4px;
    }
  }
}

.coolCard-box {
  position: relative;
  margin-top: 20px;
  padding: 0 0 20px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #ededed;

  .line {
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 0;
    background: #539dfd;
    border-radius: 4px;
    transition: all .2s;
    transform: translateX(-20px);
    z-index: 2;
  }

  &:hover .title-tip {
    transform: translateX(0);
  }

  &:hover .line {
    height: 28px;
  }
}

.coolCard {
  width: 250px;
  height: 150px;
  margin-right: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover .mask {
    left: 130%;
    transition: all 1s;
  }

  .title-tip {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 4px 6px;
    font-size: 12px;
    color: #fff;
    background: #539dfd;
    transition: all .2s;
    transform: translateX(140%);
    z-index: 2;
  }

  .mask {
    position: absolute;
    left: -130%;
    top: 0;
    width: 100%;
    height: 100%;
    transform: skew(-40deg);
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, .3), transparent);
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>