<template>
  <div class='blank'>
    请不要尝试调试本网站
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      dataStr: ''
    };
  },
  created() {
  },
  mounted() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped></style>