<template>
  <div class="room-list-box">
    <div class="room-list">
      <div class="room-item" v-for="(val, key, index) in roomList" :key="index" @click="joinRoom(val, key)">
        <div class="watch" v-if="val.player.length == 2">观战</div>
        <img class="kongwei" v-if="val.player.length > 0" src="@/assets/img/youren.png" alt="">
        <img class="kongwei" v-else src="@/assets/img/kongwei.png" alt="">
        <img class="gomokuIcon" src="@/assets/img/gomokuIcon.png" alt="">
        <img class="kongwei" v-if="val.player.length == 2" src="@/assets/img/youren.png" alt="">
        <img class="kongwei" v-else src="@/assets/img/kongwei.png" alt="">
      </div>
    </div>
    <div class="user-list">
      <userOnline :userList="userList"></userOnline>
    </div>
  </div>
</template>
<script>
import userOnline from '@/components/userOnline.vue'
export default {
  components: {
    userOnline
  },
  props: {

  },
  data() {
    return {
      roomList: [],
      userList: [],
      user: '',
      userLineList: []
    };
  },
  created() {
    this.user = this.$tool.getStorage('user')
    this.$socket.emit('userLine', this.user)
  },
  mounted() {
    this.userLine()
    this.findUser()
    this.$socket.emit('joinGomoku')
    this.$socket.on('roomList', list => {
      this.roomList = list
    })
  },
  methods: {
    findUser() {
      this.$http.post('/user/findUser', {}).then(res => {
        if (res.status === 1) {
          this.userList = res.result.filter(v => v._id !== this.user._id)
          this.userList.forEach(item => {
            if (item.avatar.indexOf('http') == -1) {
              item.avatarUrl = require(`@/assets/avatar/${item.avatar}`)
            }
          })
          this.userLineList.forEach(item => {
            this.userList.forEach(i => {
              if (item._id == i._id) i.socketId = item.socketId
            })
          })
          this.userList.forEach(item => {
            item.seq = 0
            if (item.socketId) item.seq = 1
          })
          this.userList.sort((a, b) => {
            return b.seq - a.seq
          })
        }
      })
    },
    userLine() {
      this.$socket.on('userLine', userLine => {
        this.userLineList = userLine
        userLine.forEach(item => {
          this.userList.forEach(i => {
            if (item._id == i._id) i.socketId = item.socketId
          })
        })
        this.userList.forEach(item => {
          item.seq = 0
          if (item.socketId) item.seq = 1
        })
        this.userList.sort((a, b) => {
          return b.seq - a.seq
        })
      })
    },
    joinRoom(val, key) {
      this.$router.push({ path: '/chatGame/room', query: { roomId: key } })
    }
  },
}
</script>
<style lang='scss' scoped>
.user-list {
  padding: 0 0 15px 10px;
  margin-left: 20px;
  width: 150px;
  height: 100%;
  background: #1e1e1e;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
}

.watch {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: right;
  color: #fff;
  cursor: pointer;
}

* {
  box-sizing: border-box;
  user-select: none;
}

.room-list-box {
  width: 100%;
  max-width: 800px;
  height: 600px;
  display: flex;
  position: relative;
  z-index: 1;
}

.room-list {
  width: calc(100% - 170px);
  max-width: 800px;
  height: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 20px;
  background: #1e1e1e;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  /* 调整阴影效果 */
  overflow-y: auto;
}

.room-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  background-color: #292929;
  /* 调整背景色增加立体感 */
  border-radius: 8px;
  /* 添加圆角 */
  position: relative;

  &:hover {
    .watch {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .item-content {
    display: flex;
    align-items: center;
  }

  .kongwei {
    width: 30px;
    height: 30px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
    /* 图片阴影效果 */
  }

  .gomokuIcon {
    width: 40px;
    height: 40px;
    margin: 0 15px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
    /* 图片阴影效果 */
  }
}
</style>