<template>
  <div class=''>
    <div class="article">
      <div class="article-box box-shadow editor-content-view" v-html="html">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      list: [
        { imgUrl: require('@/assets/img/rotographa.jpg') }
      ],
      html: ''
    };
  },
  mounted() {
    this.html = localStorage.getItem('html')
  },
  methods: {

  },
}
</script>
<style src="@/assets/css/view.css"></style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang='scss' scoped>
.article {
  .article-box {
    padding: 20px;
    background: #fff;
    border-radius: 5px;
  }
}
</style>